<template>
  <div class="brands-list">
    <UiBanner fluid :title="$t('brands.list.title')" :breadcrumbs="breadcrumbs">
      <template v-slot:actions>
        <div class="tw-flex tw-items-center">
          <v-text-field
            v-model="search"
            :value="search"
            :prepend-inner-icon="`${icons.mdiMagnify}`"
            :label="$t('brands.list.search.label')"
            hide-details
            outlined
            solo
            flat
            dense
            clearable
            @input="searchInput"
          />
        </div>
      </template>
    </UiBanner>

    <UiContainer no-padding>
      <div class="brands-list__table-container">
        <v-data-table
          style="cursor: pointer"
          class="brands-list__table-container__table"
          height="100%"
          item-key="id"
          fixed-header
          :headers="headers"
          :items="brands"
          :search="search"
          :options.sync="options"
          :loading="refreshing"
          :footer-props="{ itemsPerPageOptions: [1000] }"
          @click:row="goTobrandsId"
        >
          <template v-slot:[`item.defaultCountryIsoCode`]="{ item }">
            <div v-if="item.defaultCountryIsoCode" class="tw-flex tw-flex-row tw-items-center tw-gap-2">
              <div v-if="getSvgBy('country', item.defaultCountryIsoCode)">
                <v-img width="25" :src="getSvgBy('country', item.defaultCountryIsoCode)" />
              </div>
              <span>{{ availablesCountries[item.defaultCountryIsoCode] }}</span>
            </div>
          </template>
          <template v-slot:[`item.defaultLocaleIsoCode`]="{ item }">
            <div v-if="item.defaultLocaleIsoCode" class="tw-flex tw-flex-row tw-items-center tw-gap-2">
              <div v-if="getSvgBy('lang', item.defaultLocaleIsoCode)">
                <v-img width="25" :src="getSvgBy('lang', item.defaultLocaleIsoCode)" />
              </div>
              <span>{{ getLangByCode(item.defaultLocaleIsoCode) }}</span>
            </div>
          </template>
          <template v-slot:[`item.client`]="{ item }">
            {{ item.client.name }}
          </template>
        </v-data-table>
      </div>
    </UiContainer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiMagnify, mdiCheckCircle, mdiCloseCircle } from '@mdi/js'
import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'
import { debounce } from '@/utils/debounce.util'
import { getSvgBy } from '@/utils/flag.util'
import { getLangByCode } from '@/utils/languages.util'

export default {
  name: 'BrandsList',
  components: {
    UiBanner,
    UiContainer,
  },
  data: () => ({
    getSvgBy,
    getLangByCode,
    icons: {
      mdiMagnify,
      mdiCheckCircle,
      mdiCloseCircle,
    },
    refreshing: false,
    search: '',
    options: {
      size: 1000,
    },
  }),
  async created() {
    this.refreshing = true
    await this.loadBrands()
    this.refreshing = false
  },
  computed: {
    ...mapState({
      availablesCountries: state => state.gmb.availablesCountries,

      brands: state => state.brand.brands,
    }),
    breadcrumbs() {
      return [
        {
          text: this.$t('brands.list.name'),
          to: {
            name: 'brandsList',
          },
        },
      ]
    },
    headers() {
      return [
        {
          text: this.$t('brands.list.headers.name'),
          value: 'name',
          sortable: true,
        },
        {
          text: this.$t('brands.list.headers.defaultCountryIsoCode'),
          value: 'defaultCountryIsoCode',
          sortable: true,
        },
        {
          text: this.$t('brands.list.headers.defaultLocaleIsoCode'),
          value: 'defaultLocaleIsoCode',
          sortable: true,
        },
        {
          text: this.$t('brands.list.headers.client'),
          value: 'client_name',
          sortable: true,
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getBrands: 'brand/getBrands',
    }),
    searchInput: debounce(function () {
      if (this.options.page !== 1) {
        this.options.page = 1
      }
    }, 300),
    goTobrandsId(brand) {
      this.$router.push({ name: 'BrandsDashboard', params: { id: brand.uuid } })
    },
    async loadBrands() {
      this.refreshing = true
      await this.getBrands({ search: this.search ? this.search : null, options: this.options })
      this.refreshing = false
    },
  },
}
</script>

<style lang="scss" scoped>
.table-cursor tbody tr:hover {
  cursor: pointer;
}
.brands-list {
  &__table-container {
    @apply tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0;

    &__table {
      @apply tw-h-full tw-flex tw-flex-col;
    }
  }
}
</style>
